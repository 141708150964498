import React from "react"
import StructurePagePoliticas from "@components/pageLegal/templatePagePoliticas"
import { Helmet } from "react-helmet"

const PoliticasPage = ({ location }) => (
  <div>
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Politicas de Privacidad [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/politicas-privacidad/`}
      />
      <meta name="robots" content="noindex, follow"></meta>
      <meta charSet="utf-8" />
      <meta name="description" content="Politicas de Privacidad." />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Politicas de Privacidad" />
      <meta
        property="og:url"
        content={`https://beexcc.com/politicas-privacidad/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta property="og:description" content="Politicas de Privacidad." />
    </Helmet>
    <StructurePagePoliticas location={location} />
  </div>
)

export default PoliticasPage
