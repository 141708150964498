import React from "react"
import { Link } from "gatsby"
const text = (
  <span>
    <div />
    Securitec Perú S.A.C. (en adelante, Beex), identificada con RUC No.
    20600256301 y domiciliada en Jr. Pachacútec 1315, Oficina 503, distrito de
    Jesús María, provincia y departamento de Lima; es una empresa prestadora de
    servicios de software especializada en la maximización de procesos de
    contacto y comunicación con clientes, a través de diversos canales como el
    de voz, WhatsApp Business API, chat, SMS y otros, pudiendo crear y gestionar
    campañas desde cualquier lugar, permitiendo acercarse de manera más
    inteligente al cliente.
    <br /> <br />
    Beex es titular de los sitios web:{" "}
    <Link href="https://beexcc.com/">https://beexcc.com/, </Link>{" "}
    <Link href="https://beexcc.com/blog/">https://beexcc.com/blog/</Link>,
    así como de sus páginas internas y diversos productos.
    <br /> <br />
    Beex recopila información personal de quienes interactúan en nuestro
    sitio web (en adelante, “usuarios de la página web”); de quienes adquieren y
    disfrutan de nuestros productos y servicios (en adelante, “usuarios clientes
    de Beex”); así como de los clientes de estos últimos (en adelante,
    “terceros usuarios”).
    <br /> <br />
    Para brindar información sobre la clase de datos personales que se
    recopilan, cómo se obtienen y para qué se utilizan, se ha redactado esta
    Política de Privacidad (en adelante, la “Política”).
    <br /> <br />
    Para efectos de esta Política toda referencia a “nos”, “nosotros” o
    “nuestra” hace alusión a Beex y cuando se refiera a “el usuario”, “los
    usuarios”, “usuarios de la página web”, “usuarios clientes de Beex” o
    “terceros usuarios” se entenderán a todos aquellos distintos a Beex que
    naveguen, ingresen, revisen, interactúen y, especialmente, proporcionen sus
    datos personales.
    <br /> <br />
    <strong id="Objetivo"> 1. Objetivo </strong>
    <br /> <br />
    Beex se preocupa por la protección de los datos personales que recopila
    y se compromete al uso de tal información única y exclusivamente para los
    fines que se deriven de la presente Política de Privacidad. Asimismo,
    Beex asume el compromiso de procurar la seguridad de dicha información
    personal, respetar la privacidad de sus usuarios y proteger la
    confidencialidad de los datos personales recopilados.
    <br id="Legislación" /> <br />
    <strong> 2. Legislación </strong>
    <br /> <br />
    Esta política de privacidad cumple con la legislación peruana y en
    particular con la Ley N° 29733, Ley de Protección de Datos Personales y el
    Decreto Supremo N°003-2013-JUS, Reglamento de la Ley N°29733. Asimismo, el
    presente documento ha sido adecuado a los estándares establecidos en el
    Reglamento General de Protección de Datos Personales de la Unión Europea1.
    <br /> <br />
    <strong id="Qué-información-se-recopila">
      {" "}
      3. ¿Qué información se recopila?{" "}
    </strong>
    <br />
    <br />
    <strong>
      3.1. Información recopilada a través de la página web de Beex
    </strong>
    <br /> <br />
    Beex recopila la siguiente información personal de los usuarios que
    interactúan con su página web:
    <br /> <br />
    * Nombres y apellidos.
    <br />
    * Correo corporativo.
    <br />
    * Nombre de la empresa a la que el usuario pertenece.
    <br />
    * Cargo ocupado en dicha empresa.
    <br />
    * Teléfono/celular.
    <br />
    * País.
    <br />
    * Correo electrónico.
    <br />
    * Datos personales brindados a través de los mensajes remitidos al chatbot
    de Beex.
    <br /> <br />
    En el supuesto de la interposición de reclamos o quejas mediante el uso del
    Libro de Reclamaciones, además de recopilar información como el nombre,
    apellidos, teléfono/celular y correo electrónico, se recaba el tipo y número
    de documento de identidad, así como el domicilio del titular y el detalle
    del reclamo, todo ello a fin de poder atender el reclamo del usuario
    <br /> <br />
    <strong>
      3.2. Información recopilada mediante el uso de productos y servicios
      específicos de Beex
    </strong>
    <br /> <br />
    Beex recopila los siguientes datos personales de sus usuarios clientes
    cuando adquieren y utilizan un determinado producto:
    <br />
    <br />
    * Nombres y Apellidos.
    <br />
    * Correo electrónico.
    <br />
    * Teléfono/celular.
    <br />
    * ID y contraseña de usuario.
    <br />
    * Tipo de usuario (administrador, agente o supervisor).
    <br />
    * Estado de usuario.
    <br />
    * Fecha de creación del usuario.
    <br />
    * Género.
    <br />
    * Imagen.
    <br />
    * Voz.
    <br />
    * Mensajes entre usuarios clientes de Beex.
    <br />
    * Acciones en tiempo real realizada por los usuarios clientes de Beex
    (tiempo de eventos en línea, tiempos de trabajo y tiempos de desconexión).
    <br /> <br />
    Puede que Beex tenga acceso a la imagen de perfil y nombre de usuario
    de quienes decidan vincular sus cuentas de Facebook, Instagram, WhastApp u
    otras redes sociales o plataformas, con los productos de Beex. Esto se
    realiza para fines relacionados con la prestación de nuestros servicios y se
    sujeta a las propias políticas de privacidad de dichos medios que fueron
    previamente aceptados por los usuarios clientes de Beex (para más
    información, <Link href="#Integración-con-proveedores"> ver punto 8 </Link>).
    <br /> <br />
    <strong> 3.3. Información personal de terceros usuarios </strong>
    <br /> <br />
    La naturaleza de los productos y servicios de Beex hace posible que
    nuestros usuarios clientes nos proporcionen datos personales de terceros (es
    decir, de sus propios clientes) como los siguientes:
    <br /> <br />
    * Nombres y apellidos.
    <br />
    * Correo electrónico.
    <br />
    * Teléfono/celular.
    <br />
    * Domicilio.
    <br />
    * Tipo y documento de identidad.
    <br />
    * Fecha de nacimiento.
    <br />
    * Código de país.
    <br />
    * Mensajes.
    <br />
    * Y, en general, toda aquella información esencial que el usuario cliente
    necesite recopilar para fines de uso de los productos de Beex.
    <br /> <br />
    En ese supuesto, al no tener control sobre el ingreso de la información de
    terceros y al no recabar directamente dicha información de sus titulares,
    Beex actúa como un
    <strong>
      {" "}
      encargado de tratamiento de la información personal de dichos terceros
    </strong>
    , siendo la responsabilidad de nuestros usuarios clientes obtener
    previamente su consentimiento de manera libre, expresa, inequívoca e
    informada.
    <br /> <br />
    Por tanto, el usuario cliente de Beex deberá asegurarse de obtener la
    autorización de la persona cuyos datos personales utiliza y almacena para el
    uso de nuestros productos. No nos hacemos responsables por la finalidad que
    el usuario cliente dé a dichos datos personales.
    <br /> <br />
    <strong id="Para-qué-se-utiliza-esta-información-personal">
      {" "}
      4. ¿Para qué se utiliza esta información personal?{" "}
    </strong>
    <br /> <br />
    Los datos personales mencionados serán tratados con las siguientes
    finalidades:
    <br /> <br />
    * Para fines de la prestación de nuestros servicios.
    <br />
    * Para fines del acceso y uso de nuestros productos.
    <br />
    * Brindar soporte técnico.
    <br />
    * Para notificar cambios en nuestro servicio.
    <br />
    * Validar la información proporcionada y su veracidad.
    <br />
    * Personalizar, desarrollar y mejorar nuestros servicios y la experiencia
    del usuario en Beex.
    <br />
    * Resolver y dar respuesta a las quejas y problemas respecto al servicio,
    así como absolver consultas, peticiones, sugerencias y otro tipo de
    información que se requiera a través de nuestros canales de comunicación.
    <br />
    * Facilitar cualquier información que Beex crea que puede ser de
    interés del usuario.
    <br />
    * Enviar comunicaciones relevantes o exclusivas correspondientes a la
    prestación de nuestros servicios.
    <br />
    * Enviar publicidad sobre nuestros productos/servicios; novedades;
    actualizaciones; información de los artículos que publicamos en nuestro
    blog; información e invitaciones a talleres, cursos, webinars, bootcamps y
    similares, en caso así el usuario lo haya consentido expresamente.
    <br />
    * Para coordinar demostraciones.
    <br />
    * Brindar acceso gratuito a descarga de contenido digital ofrecidos en
    nuestra página web.
    <br />
    * Investigar y detectar posibles fraudes u otras violaciones a nuestros
    Términos y Condiciones o contra la presente Política de Privacidad, y/o
    intentos de dañar a nuestros usuarios o visitantes. También usamos los datos
    personales brindados si creemos que es necesario por motivos de seguridad.
    <br />
    * Utilizar información debidamente anonimizada o disociada con el fin de
    desarrollar nuestros servicios, investigar tendencias en nuestro sector o
    realizar cualquier tipo de actividades de valor histórico o estadístico.
    <br />
    * Realizar sondeos y encuestas sobre nuestros servicios, así como medir su
    rendimiento.
    <br />
    * Almacenar la información en servicios de almacenamiento en la nube en el
    extranjero. Ello resulta indispensable para la prestación de los servicios
    de Beex.
    <br /> <br />
    En resumen, la base jurídica de la que Beex se vale para utilizar los
    datos personales recopilados en la siguiente:
    <br /> <br />
    * El consentimiento del usuario.
    <br />
    * Para brindar nuestros servicios y mejorar la experiencia del usuario.
    <br />
    * Para fines de funcionalidad y operatividad de la página web.
    <br />
    * Para poder brindar soporte, resolver cualquier duda o consulta y resolver
    reclamos.
    <br />
    * Para proteger la seguridad de nuestros usuarios, página web y productos.
    <br />
    * Para satisfacer un interés legítimo (que no anula nuestro deber de
    proteger los datos personales recopilados) como la investigación y el
    desarrollo de nuestros servicios, promover el sitio web y proteger nuestros
    derechos e intereses legales.
    <br />
    * Para procesar los datos con el objeto de cumplir con una obligación legal.
    <br />
    * Para procesar los datos con el objeto de cumplir con una obligación
    contractual.
    <br /> <br />
    <strong id="Calidad-de-los-datos-personales">
      {" "}
      5. Calidad de los datos personales{" "}
    </strong>
    <br /> <br />
    Los datos personales solicitados tienen el carácter de adecuados,
    pertinentes y no excesivos en relación con la finalidad para la cual se
    recopilan.
    <br /> <br />
    El usuario declara que toda la información proporcionada es verdadera,
    completa, exacta y actualizada. Asimismo, el usuario asume la
    responsabilidad sobre la veracidad, exactitud, integridad y vigencia de
    dicha información.
    <br /> <br />
    De igual forma, el usuario se compromete a mantener permanentemente
    actualizada toda la información facilitada a Beex, de forma que
    responda, en cada momento, a su situación real y actual. Beex no asume
    la responsabilidad por cualquier daño o perjuicio que pudiera ocasionarse
    como resultado del incumplimiento de tal deber.
    <br /> <br />
    El incumplimiento de este deber se sujeta a las sanciones previstas en los
    Términos y Condiciones de Beex.
    <br /> <br />
    <strong id="Bancos-de-datos"> 6. Bancos de datos </strong>
    <br /> <br />
    Los datos personales se almacenarán en los siguientes bancos de datos:
    <br /> <br />
    * Banco de datos “Clientes”, con ingreso de solicitud No. 100517-2021MSC
    <br />
    * Banco de datos “Proveedores”, con ingreso de solicitud No. 100453-2021MSC
    <br />
    * Banco de datos “Usuarios de la página web”, con ingreso de solicitud No.
    100455-2021MSC
    <br />
    * Banco de datos “Trabajadores”, con ingreso de solicitud No. 100432-2021MSC
    <br />
    * Banco de datos “Videovigilancia”, con ingreso de solicitud No.
    100436-2021MSC
    <br />
    * Banco de datos “Libro de reclamaciones”, con ingreso de solicitud No.
    _____. (Pendiente)
    <br /> <br />
    <strong id="Transferencia-de-datos-personales-a-terceros">
      {" "}
      7. Transferencia de datos personales a terceros{" "}
    </strong>
    <br /> <br />
    No se transfiere información a terceros a menos de que sea necesario para
    llevar a cabo la gestión, desarrollo y ejecución del servicio. En dicho
    caso, los datos personales recopilados serán compartidos de la siguiente
    forma:
    <br /> <br />
    <strong> 7.1. Usuarios autorizados </strong>
    <br /> <br />
    El usuario cliente, que tiene el rol de administrador o superadministrador
    de la cuenta creada para el uso de los productos de Beex, puede
    habilitar un número determinado de usuarios (por ejemplo, para trabajadores
    o locadores), quienes igualmente tendrán acceso a la información personal
    almacenada en la cuenta adquirida por su titular, ya sea en calidad de
    administradores, agentes o supervisores.
    <br /> <br />
    En este supuesto, Beex no se responsabiliza por las acciones que los
    nuevos usuarios realicen deliberadamente respecto a la información personal
    a la que tienen acceso (dado que Beex no tiene control sobre ello), sin
    perjuicio de tomar las medidas correspondientes por violación a nuestros
    Términos y Condiciones (ej. Baja de usuario).
    <br /> <br />
    <strong> 7.2. Terceros proveedores </strong>
    <br /> <br />
    Beex podrá transferir los datos personales referidos anteriormente a
    empresas subcontratadas, tales como empresas procesadoras de pago, entre
    otros proveedores de servicios única y exclusivamente para fines de la
    prestación del servicio. Si quieres conocer sobre ellas, puedes revisar la
    lista de empresas subcontratadas en el siguiente{" "}
    <Link target="_blank" href="/proveedores_locales"   rel="noopener noreferrer">
      link.
    </Link>
    <br /> <br />
    El usuario podrá acceder a la información de terceros proveedores ubicados
    fuera del territorio peruano, dirigiéndose al punto 9 de la presente
    Política.
    <br /> <br />
    Adicionalmente, el usuario cliente declara y garantiza que la cuenta creada
    es para el uso exclusivo de los usuarios designados. Beex no se
    responsabiliza por el otorgamiento de accesos no autorizados a terceros
    ajenos a la cuenta.
    <br /> <br />
    <strong> 7.3. Autoridades </strong>
    <br /> <br />
    Los datos personales podrán ser transferidos a las entidades
    administrativas, autoridades judiciales y/o policiales, siempre y cuando
    ello esté establecido por Ley o por mandato judicial.
    <br /> <br />
    De otro lado,{" "}
    <Link href="#Ejercicio-de-derechos-ARCO-y-otros-derechos">
      {" "}
      en el punto 13.3{" "}
    </Link>
    de esta Política, se ha designado a un representante y delegado encargado de
    velar por la protección y adecuado tratamiento de la información personal
    recolectada. Como tal, éste se ocupará de brindar a las autoridades, toda la
    información que éstas nos soliciten sobre el usuario. Además, esta persona
    tendrá la obligación de notificar a las autoridades sobre los incidentes
    reportados relacionados a la vulneración de dichos datos personales.
    <br /> <br />
    <strong id="Integración-con-proveedores">
      8. Integración con proveedores e información recolectada por redes
      sociales
    </strong>
    <br /> <br />
    Los servicios de Beex contienen enlaces a otras plataformas y redes
    sociales, tales como, “Facebook” y “LinkedIn”. Por ejemplo, a través de
    nuestro blog es posible compartir el artículo visualizado en dichas redes
    sociales. De igual forma, es posible acceder a redes sociales como Facebook
    para utilizar un determinado producto.
    <br /> <br />
    En tal sentido, Beex no tiene el control sobre dichas redes sociales y
    no es responsable de su contenido, sus políticas de privacidad, medidas de
    seguridad y el uso que éstos realizan con los datos personales que
    recopilan.
    <br /> <br />
    La obtención de la información personal de los usuarios de Beex por
    parte de estas plataformas y redes sociales, escapa de nuestro control,
    siendo ajena al tratamiento que realizamos con sus datos personales.
    Cualquier información remitida a estos terceros está sujeta a la política de
    privacidad de dicho tercero y no a la de Beex.
    <br /> <br />
    Por ello, recomendamos a nuestros usuarios revisar detalladamente los
    términos de uso y las políticas de privacidad de dichos de terceros para
    comprender cómo recopilan, usan y comparten su información personal con
    nosotros o con otros terceros.
    <br /> <br />
    Este apartado aplica también cuando el cliente usuario decide adquirir por
    sí mismo la API de WhatsApp for Business de prestadores oficiales, para el
    uso integral de la Plataformas y de los Servicios ofrecidos por Beex.
    <br /> <br />
    <strong id="Flujo-transfronterizo"> 9. Flujo transfronterizo </strong>
    <br /> <br />
    El sistema de información de Beex se encuentra alojado en servidores no
    ubicados en territorio peruano. En tal sentido, se informa que Beex
    realiza flujo transfronterizo de los datos personales de los usuarios, en
    tanto utiliza servicios de almacenamiento en la nube de las empresas
    detalladas en el siguiente{" "}
    <Link target="_blank" href="/proveedores_extranjeros"   rel="noopener noreferrer">
      link.
    </Link>
    <br /> <br />
    También se realiza flujo transfronterizo de datos al utilizar los servicios
    de terceros proveedores, cuya información puede ser accesible mediante el
    siguiente{" "}
    <Link target="_blank" href="/servicios_extranjeros"   rel="noopener noreferrer">
      link.
    </Link>
    <br /> <br />
    <strong id="Conservación-de-los-datos-personales">
      {" "}
      10. Conservación de los datos personales{" "}
    </strong>
    <br /> <br />
    La información recopilada por Beex será almacenada mientras dure la
    relación contractual con nosotros o la finalidad para la cual los datos han
    sido recopilados. En todo caso, el titular del dato personal tiene derecho a
    revocar su consentimiento en cualquier momento de acuerdo al
    <Link href="#Ejercicio-de-derechos-ARCO-y-otros-derechos"> punto 13.</Link>
    <br /> <br />
    Beex puede conservar información debidamente anonimizada o disociada,
    inclusive después de cerrada una cuenta, con el fin de desarrollar nuevos
    servicios, investigar tendencias del sector educativo, entre otros fines
    académicos y científicos. Ello debido a que los datos personales sometidos a
    un proceso de anonimización o disociación impiden la identificación de su
    titular.
    <br /> <br />
    <strong id="Confidencialidad-de-los-datos-personales">
      {" "}
      11. Confidencialidad de los datos personales{" "}
    </strong>
    <br /> <br />
    Beex trata con absoluta confidencialidad los datos personales a los que
    tiene acceso, pudiendo ser estos conocidos y manejados por personal de
    Beex que necesite conocer dicha información para realizar las labores
    comprendidas en las finalidades descritas anteriormente.
    <br /> <br />
    <strong id="Seguridad-de-los-datos-personales">
      {" "}
      12. Seguridad de los datos personales{" "}
    </strong>
    <br /> <br />
    En cumplimiento de la normativa vigente, hemos adoptado las medidas técnicas
    y organizativas de seguridad necesarias para garantizar la protección de los
    datos personales conservados, transmitidos y procesados de los usuarios de
    Beex con el fin de evitar su alteración, pérdida o el tratamiento o
    acceso no autorizados que puedan afectar a la integridad, confidencialidad y
    disponibilidad de la información, teniendo en cuenta el estado de la
    tecnología, la naturaleza de los datos almacenados y los riesgos a los que
    están expuestos.
    <br /> <br />
    Entre las medidas de gestión de riesgo que se adoptan, figuran aquellas cuya
    finalidad consiste en determinar todo riesgo de incidentes, prevenir,
    detectar y gestionar incidentes y mitigar sus repercusiones.
    <br /> <br />
    No obstante, a pesar de que en Beex se realizan esfuerzos para proteger
    los datos personales recopilados mediante el empleo de diversas técnicas de
    seguridad, la transmisión de información mediante las redes de comunicación
    y de Internet no es totalmente segura debido a actos ilícitos como
    ciberataques o acciones imputables al propio usuario al no tener precaución
    con sus propios datos.
    <br /> <br />
    En tal sentido, toda la información proporcionada se enviará por cuenta y
    riesgo del usuario. Beex no se hace responsable por interceptaciones
    ilegales o violación de sus sistemas o bases de datos por parte de personas
    no autorizadas, así como la indebida utilización de la información obtenida
    por esos medios, o de cualquier intromisión ilegítima que escape al control
    de ésta y que no le sea imputable.
    <br /> <br />
    Adicionalmente, a pesar de que Beex vela por la protección de la
    información personal de sus usuarios, éstos son responsables de no divulgar
    datos que permitan a terceros, tener acceso a los mismos (por ejemplo,
    contraseña e identificación de usuario). El usuario es el único que conoce
    esta información, por lo que no deberá revelarla a terceras personas.
    <br /> <br />
    En caso el usuario considere que existió algún tipo de violación de
    seguridad o identifica que alguien accedió a su cuenta, deberá notificarlo
    inmediatamente a Beex por medio del correo electrónico proporcionado en
    el punto 17 de la presente Política.
    <br /> <br />
    De igual forma, en caso Beex detecte una violación a la seguridad de
    los datos personales proporcionados, y ello entrañe un alto riesgo para los
    derechos y libertades del usuario, Beex se lo comunicará en un lenguaje
    claro y sencillo, de modo que pueda comprender fácilmente la naturaleza de
    la infracción cometida contra sus datos personales.
    <br /> <br />
    Dicha comunicación no será necesaria cuando Beex haya adoptado medidas
    de protección técnicas y organizativas apropiadas aplicadas a los datos
    personales afectados, de modo que éstos resulten ininteligibles para quien
    intentó acceder a ellos (ej. el cifrado). Tampoco será necesario cuando
    suponga un esfuerzo desproporcionado para Beex, ya que tratándose de
    varios usuarios afectados resulta más fácil optar por una comunicación
    pública u otras opciones.
    <br /> <br />
    <strong id="Ejercicio-de-derechos-ARCO-y-otros-derechos">
      {" "}
      13. Ejercicio de derechos ARCO y otros derechos{" "}
    </strong>
    <br /> <br />
    Los derechos ARCO (Acceso, Rectificación, Cancelación y Oposición) son el
    conjunto de derechos a través de los cuales puedes ejercer el control sobre
    tus datos personales.
    <br /> <br />
    Estos sirven para: (i) Acceder a información personal del titular en
    nuestras bases de datos; (ii) Rectificar estos datos; (iii) Eliminar dichos
    datos de nuestra base de datos; y, (iv) Oponerse a que los datos del usuario
    se usen si se descubre que están siendo usados para un fin no descrito en
    este documento.
    <br /> <br />
    No podrá solicitar la eliminación de sus datos personales el usuario que aún
    mantiene una relación activa con Beex, o si su intención expresa es de
    consumir nuestros servicios. No estamos obligados a suprimir los datos
    personales solicitados cuando el tratamiento de éstos sea necesario para
    ejecutar una obligación vigente entre Beex y el usuario, cumplir una
    obligación legal, por razones de interés público o para emprender alguna
    acción legal contra los usuarios.
    <br /> <br />
    Tampoco estamos obligados a suprimir datos personales cuando el tratamiento
    de éstos sea necesario para respetar la libertad de expresión y de
    información, por razones de interés público o para emprender alguna acción
    legal contra nuestros usuarios.
    <br /> <br />
    El usuario podrá comunicarse con nosotros para ejercer sus derechos de
    información, de acceso, de actualización, inclusión, rectificación y
    supresión; de impedir el suministro de sus datos personales, de oposición al
    tratamiento o de tratamiento objetivo de los datos, en los términos
    recogidos en la legislación peruana vigente y de acuerdo a los estándares de
    la Unión Europea aquí expuestos.
    <br /> <br />
    <strong>
      Estos derechos se ejercen presentando una solicitud vía correo electrónico
      a
    </strong>
    <Link href="mailto:hi@beexcc.com"> hi@beexcc.com.</Link> Para ello, se
    deberá remitir la siguiente información:
    <br /> <br />
    * Copia de DNI, carnet de extranjería o pasaporte.
    <br />
    * Pedido sobre las medidas que Beex debería tomar respecto a los datos
    personales solicitados.
    <br />
    * Documentos que sustenten el pedido, de ser el caso.
    <br /> <br />
    Beex responderá la solicitud a través del correo electrónico
    proporcionado por el usuario. El plazo de respuesta dependerá del tipo de
    solicitud formulado, conforme al Decreto Supremo N° 003-2013-JUS, Reglamento
    de la Ley N° 29733.
    <br /> <br />
    En caso no sea posible atender dicha solicitud por falta de información o de
    precisión, se comunicará tal situación al usuario solicitante en los plazos
    detallados por la norma para ello.
    <br /> <br />
    <strong> 13.1. Derecho a la portabilidad de datos personales </strong>
    <br /> <br />
    El usuario tiene derecho a solicitar la devolución de los datos personales
    facilitados a Beex o solicitar que los mismos sean transmitidos a otra
    (s) empresa (s), siempre y cuando el tratamiento de esta información
    provenga de su consentimiento válido o de un contrato. Dichos datos le serán
    facilitados en un formato de uso común, comprensible y de lectura
    automática.
    <br /> <br />
    Para ello, podrá dirigir su solicitud al siguiente correo electrónico
    <Link href="mailto:hi@beexcc.com"> hi@beexcc.com</Link> y seguir los pasos
    señalados en el
    <Link href="#Otros"> punto 17.</Link>
    <br /> <br />
    <strong>
      13.2. Derecho a recurrir ante la Autoridad de Protección de Datos
      Personales
    </strong>
    <br /> <br />
    En Beex cumplimos con comunicar al usuario que puede dirigirse ante la
    autoridad encargada de velar por la protección de sus datos personales de su
    país, en caso de que advierta algún tratamiento irregular de su información
    personal por parte de Beex.
    <br /> <br />
    En ese caso, antes de ello recomendamos a nuestros usuarios tratar de
    comunicarse con nosotros a través del correo{" "}
    <Link href="mailto:hi@beexcc.com">hi@beexcc.com.</Link> a fin de que
    podamos dar solución a cualquier inquietud o problema en relación al
    tratamiento de sus datos personales.
    <br /> <br />
    <strong>
      13.3. Responsable del tratamiento, Representante y Delegado de Protección
      de Datos Personales
    </strong>
    <br /> <br />
    Informamos a nuestros usuarios que Beex, cuyos datos de identificación
    constan al inicio de la presente Política, es la empresa responsable del
    tratamiento de los datos personales recopilados. Como tal, nos
    responsabilizamos por el tratamiento lícito, leal, transparente, adecuado,
    pertinente y seguro de los datos personales proporcionados, así como por la
    obtención del consentimiento libre, previo, informado, expreso e inequívoco
    de nuestros usuarios.
    <br /> <br />
    Asimismo, dado que nuestros servicios se brindan más allá del territorio
    peruano, se ha optado por nombrar a un representante, quien actuará en
    nombre de Beex a nivel internacional, específicamente en los países
    donde nuestros servicios son accesibles. Su identificación se detalla a
    continuación:
    <br /> <br />
    * Nombres y Apellidos: Max Arturo Aguirre Omonte.
    <br />
    * Correo electrónico de contacto: max.aguirre@beexcc.com.
    <br /> <br />
    Este representante también tiene la condición de “delegado de protección de
    datos” encargado de supervisar el tratamiento de la información personal
    recopilada y de informar y asesorar al personal correspondiente sobre sus
    obligaciones en temas de protección de datos personales.
    <br /> <br />
    <strong id="Menores-de-edad"> 14. Menores de edad </strong>
    <br /> <br />
    Los productos y servicios de Beex no están dirigidos, ni diseñados para
    menores de edad (menores de 18 años). Sin embargo, no tenemos ninguna forma
    de validar que los datos ingresados sean de una persona mayor de edad, por
    lo que no nos hacemos responsables de las acciones de los usuarios que han
    declarado erróneamente ser mayores de edad.
    <br /> <br />
    En el supuesto que se tenga conocimiento que los datos personales recogidos
    corresponden a un menor de edad sin el consentimiento del padre, tutor o
    encargado, se adoptarán las medidas oportunas para eliminar estos datos tan
    pronto como sea posible. Además, se invalidará toda participación en el
    proceso en el que haya participado el menor de edad.
    <br /> <br />
    De igual forma, de considerar que hemos recopilado información personal
    sobre algún menor de edad, dicha situación deberá ser comunicada a Beex
    a través del correo electrónico proporcionado en el{" "}
    <Link href="#Otros"> punto 17.</Link>
    <br /> <br />
    <strong id="Sobre-las-consecuencias-de-proporcionar">
      15. Sobre las consecuencias de proporcionar la información personal
      requerida y la negativa de hacerlo
    </strong>
    <br /> <br />
    En caso no se permita a Beex acceder a la información personal
    requerida, no podremos brindar los servicios que prestamos a través de
    nuestra página web, ni otorgar acceso a los productos que ofrecemos. Tampoco
    podremos absolver consultas, reclamos o quejas.
    <br /> <br />
    <strong id="Consentimiento"> 16. Consentimiento </strong>
    <br /> <br />
    Al aceptar la presente el usuario brinda su consentimiento libre, expreso,
    inequívoco e informado para tratar tus datos personales conforme a las
    finalidades expuestas anteriormente.
    <br /> <br />
    <strong id="Otros"> 17. Otros </strong>
    <br /> <br />
    Para realizar cualquier tipo de consulta respecto a esta Política, el
    usuario podrá escribirnos a la siguiente dirección de correo electrónico:{" "}
    <Link href="mailto:hi@beexcc.com"> hi@beexcc.com. </Link>
    <br /> <br />
    <strong id="Modificación-de-la-presente-política">
      {" "}
      18. Modificación de la presente política de privacidad{" "}
    </strong>
    <br /> <br />
    Beex se reserva el derecho a modificar su Política de Privacidad en el
    supuesto de que exista un cambio en la legislación vigente, doctrinal,
    jurisprudencial o por criterios propios empresariales. Si se introdujera
    algún cambio en esta política, el nuevo texto se publicará y se solicitará
    previamente el consentimiento respecto de tales cambios.
  </span>
)

const data = {
  description: {
    title: "Políticas de privacidad",
    textbody: text,
    intro: "Introducción",
    class: "container-menu-politicas",

    menu: [
      {
        text: "Introducción",
        href: "#Introducción",
      },
      {
        text: "Objetivo",
        href: "#Objetivo",
      },
      {
        text: "Legislación",
        href: "#Legislación",
      },
      {
        text: "¿Qué información se recopila?",
        href: "#Qué-información-se-recopila",
      },

      {
        text: "¿Para qué se utiliza esta información personal?",
        href: "#Para-qué-se-utiliza-esta-información-personal",
      },
      {
        text: "Calidad de los datos personales",
        href: "#Calidad-de-los-datos-personales",
      },
      {
        text: "Bancos de datos",
        href: "#Bancos-de-datos",
      },
      {
        text: "Transferencia de datos personales a terceros",
        href: "#Transferencia-de-datos-personales-a-terceros",
      },
      {
        text:
          "Integración con proveedores e información recolectada por redes sociales",
        href: "#Integración-con-proveedores",
      },

      {
        text: "Flujo transfronterizo",
        href: "#Flujo-transfronterizo",
      },

      {
        text: "Conservación de los datos personales",
        href: "#Conservación-de-los-datos-personales",
      },
      {
        text: "Confidencialidad de los datos personales",
        href: "#Confidencialidad-de-los-datos-personales",
      },
      {
        text: "Seguridad de los datos personales",
        href: "#Seguridad-de-los-datos-personales",
      },
      {
        text: "Ejercicio de derechos ARCO y otros derechos",
        href: "#Ejercicio-de-derechos-ARCO-y-otros-derechos",
      },
      {
        text: "Menores de edad",
        href: "#Menores-de-edad",
      },
      {
        text:
          "Sobre las consecuencias de proporcionar la información personal requerida y la negativa de hacerlo",
        href: "#Sobre-las-consecuencias-de-proporcionar",
      },
      {
        text: "Consentimiento",
        href: "#Consentimiento",
      },
      {
        text: "Otros",
        href: "#Otros",
      },
      {
        text: "Modificación de la presente política de privacidad",
        href: "#Modificación-de-la-presente-política",
      },
    ],
  },
}

export default data
